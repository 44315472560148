import classNames from 'classnames';
import { ReactNode } from 'react';
import NavigationButton from '~/components/interactive/NavigationButton';

type MessageType = 'info' | 'warning' | 'error' | 'success';

export interface MessageProps {
  type: MessageType;
  children: ReactNode;
}

export function ButtonMessage({ message, type, children }: { message: string } & MessageProps) {
  return (
    <Message type={type}>
      <div className="flex space-x-2 items-center">
        {children}
        <div>{message}</div>
      </div>
    </Message>
  );
}

export default function Message({ type, children }: MessageProps) {
  return (
    <div
      className={classNames('p-3 border rounded-md border-l-[4px] text-gray-900 w-full text-sm', {
        'border-blue-500 bg-blue-50': type === 'info',
        'border-yellow-500 bg-yellow-50': type === 'warning',
        'border-red-500 bg-red-50': type === 'error',
        'border-green-500 bg-green-50': type === 'success',
      })}
    >
      {children}
    </div>
  );
}
